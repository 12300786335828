<template>
  <div class="signup-page">
    <md-content class="md-elevation-3" v-if="!userSaved">
      <div class="title md-layout">
        <md-avatar class="md-primary md-large">
          <img :src="logo" class="svg-img brand-logo" />
        </md-avatar>
        <div class="md-title md-layout-item title-text">
          {{ $t("signup.title") }}
        </div>
      </div>
      <form
        id="signup-form"
        novalidate
        class="md-layout"
        @submit.prevent="validateSignup"
      >
        <span class="success-class" v-if="emailSentMsg">{{
          emailSentMsg
        }}</span>
        <span class="error-class" v-if="alert">{{ alert }}</span>

        <md-field :class="getValidationClass('name')" class="name-field">
          <label for="email">{{ $t("fieldName.name") }}</label>
          <md-input
            name="name"
            id="name"
            :placeholder="$t('fieldName.name')"
            v-model="$v.form.name.$model"
            :disabled="sending"
          />
          <span class="md-error" v-if="!$v.form.name.required">{{
            $t("users.nameRequired")
          }}</span>
          <span class="md-error" v-else-if="!$v.form.name.minLength">{{
            $t("users.nameInvalid")
          }}</span>
        </md-field>

        <md-field :class="getValidationClass('email')" class="email-field">
          <label for="email">{{ $t("fieldName.email") }}</label>
          <md-input
            name="email"
            id="email"
            :placeholder="$t('fieldName.email')"
            v-model="$v.form.email.$model"
            :disabled="sending"
          />
          <span class="md-error" v-if="!$v.form.email.required">{{
            $t("users.emailRequired")
          }}</span>
          <span class="md-error" v-else-if="!$v.form.email.email">{{
            $t("users.emailInvalid")
          }}</span>
        </md-field>

        <md-field
          :class="getValidationClass('password')"
          class="password-field"
          md-has-password
        >
          <label for="password">{{ $t("fieldName.password") }}</label>
          <md-input
            name="password"
            id="password"
            :placeholder="$t('fieldName.password')"
            v-model="$v.form.password.$model"
            :disabled="sending"
            type="password"
          />
          <span class="md-error" v-if="!$v.form.password.required">{{
            $t("users.passwordRequired")
          }}</span>
          <span class="md-error" v-else-if="!$v.form.password.passwordMinLength">{{
            $t("users.passwordInvalidLength")
          }}</span>
          <span class="md-error" v-else-if="!$v.form.password.containsUppercase">{{
            $t("users.passwordInvalidCase")
          }}</span>
          <span class="md-error" v-else-if="!$v.form.password.containsLowercase">{{
            $t("users.passwordInvalidCase")
          }}</span>
          <span class="md-error" v-else-if="!$v.form.password.containsNumber">{{
            $t("users.passwordInvalidNumber")
          }}</span>
          <span class="md-error" v-else-if="!$v.form.password.containsSpecial">{{
            $t("users.passwordInvalidSpecial")
          }}</span>
        </md-field>

        <div class="md-layout md-layout-item md-size-100 md-alignment-center-left">
          <md-checkbox class="terms-and-condition-class md-primary md-layout-item md-size-5 md-xsmall-size-10"
            v-model="form.mfa_enabled"
          ></md-checkbox>
          <span class="md-layout-item md-size-90 md-xsmall-size-85 terms-class align-start">
            Use MFA
          </span>
        </div>

        <div class="md-layout md-layout-item md-size-100 md-alignment-center-left">
          <md-checkbox
            class="terms-and-condition-class md-primary md-layout-item md-size-5 md-xsmall-size-10"
            v-model="termAndCondition"
          >
            <md-tooltip
              :md-active.sync="termsAndConditionChecked"
              md-direction="top"
              >{{ $t("users.checkedBox") }}
            </md-tooltip>
          </md-checkbox>
          <span class="md-layout-item md-size-90 md-xsmall-size-85 terms-class"
          >{{ $t("termAndPrivacy.agree") }}
            <a
              target="_blank"
              href="https://stenoris.com/terms-and-conditions/"
              >{{ $t("termAndPrivacy.term") }}</a
            >
            {{ $t("termAndPrivacy.and") }}
            <a target="_blank" href="https://stenoris.com/privacy-policy/">{{
              $t("termAndPrivacy.privacy")
            }}</a>
            {{ $t("termAndPrivacy.ofInterscriber") }}
          </span>
        </div>

        <span class="login-link md-layout-item md-size-100"
          >{{ $t("users.alreadySignup")
          }}<a href="/login"> {{ $t("buttons.loginButton") }}</a>
        </span>

        <div class="actions md-layout md-alignment-center-right">
          <md-button
            type="submit"
            class="md-raised md-primary"
            for="signup-form"
            :disabled="sending"
            >{{ $t("signup.submitButton") }}</md-button
          >
        </div>
      </form>
      <md-content v-if="mfa.secret != '' && mfa.uri != ''">
        <SetupMFA
          :with_qr="true"
          :with_password="false"
          :mfa_secret="mfa.secret"
          :mfa_uri="mfa.uri"
          :button_text="$t('mfaTab.confirmMfa')"
          @confirm_token="this.confirm_mfa_token"
        ></SetupMFA>
      </md-content>
    </md-content>
    <md-content class="md-elevation-3 message-container" v-else>
      <span class="md-title success-class">{{
        $t("signupMessage.email")
      }}</span>
    </md-content>
    <md-dialog class="spinner-dialog-box" :md-active.sync="sending">
      <Spinner></Spinner>
    </md-dialog>
    <div class="background" />
  </div>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Spinner from "../components/ProgressSpinner";
import SetupMFA from "../components/SetupMFA";
import logo from "../assets/Stenoris_Logo_min.svg";

export default {
  name: "SignupPage",
  components: {
    Spinner,
    SetupMFA,
  },
  data: () => ({
    logo,
    sending: false,
    userSaved: false,
    userId: null,
    mfa_setup: false,
    alert: "",
    emailSentMsg: "",
    form: {
      name: "",
      email: "",
      password: "",
      mfa_enabled: false,
    },
    termAndCondition: false,
    termsAndConditionChecked: false, // flag for tooltip in checkbox.
    proposeParticipation: false,
    mfa: {
      secret: "",
      uri: ""
    }
  }),
  computed: {
    ...mapGetters("user", {
      fieldLength: "getFieldLength",
      timeout: "getTimeoutDuration",
    }),
    isPotentialSummaryTestSubject: function () {
      return false;
    },
  },
  validations() {
    const containsUppercase = (value) => /[A-Z]/.test(value);
    const containsLowercase = (value) => /[a-z]/.test(value);
    const containsNumber = (value) => /[0-9]/.test(value);
    const containsSpecial = (value) => /[*+.,:;_<=>?@!#$%&'^`(){|}~\"\-\/\\\[\]]/.test(value);
    const passwordMinLength = (value) => /.{8,}/.test(value);
    const data = {
      form: {
        name: {
          required,
          minLength: minLength([this.fieldLength.name]),
        },
        email: {
          required,
          email,
        },
        password: {
          required,
          passwordMinLength,
          containsUppercase,
          containsLowercase,
          containsNumber,
          containsSpecial
        },
      },
    };
    return data;
  },
  watch: {
    emailSentMsg() {
      if (this.emailSentMsg) {
        setTimeout(() => {
          this.emailSentMsg = "";
        }, this.timeout);
      }
    },
    alert() {
      if (this.alert) {
        setTimeout(() => {
          this.alert = "";
        }, this.timeout);
      }
    },
  },
  methods: {
    ...mapActions("user", ["signupUser", "signupUserMFA", "signupUserMFAConfirm"]),
    ...mapMutations("user", ["setLocales"]),
    validateField(property) {
      this.$v.form[property].$touch();
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    signup() {
      if (this.termAndCondition) {
        this.sending = true;
        if (this.form.mfa_enabled) {
          // signup with mfa
          this.signupUserMFA({ formData: this.form })
            .then((response) => {
              this.sending = false;
              this.mfa.secret = response.data.mfa_secret;
              this.mfa.uri = response.data.mfa_uri;
              this.userId = response.data.data.id;
            })
            .catch(({ response }) => {
              this.sending = false;
              this.alert = response && response.data && response.data.error;
            });
        }
        else {
          // signup without mfa
          this.signupUser({ formData: this.form })
            .then((response) => {
              this.sending = false;
              this.emailSentMsg = response.data.msg;
              this.userSaved = true;
            })
            .catch(({ response }) => {
              this.sending = false;
              this.alert = response && response.data && response.data.error;
            });
        }
      } else if (this.termAndCondition && this.isPotentialSummaryTestSubject) {
        this.proposeParticipation = true;
      } else {
        this.termsAndConditionChecked = true;
      }
    },
    confirm_mfa_token(mfa_form) {
      data = {
        id: this.userId,
        password: this.form.password,
        mfa_token: mfa_form.mfa_token
      }
      this.signupUserMFAConfirm({formData: data})
        .then((response) => {
          this.emailSentMsg = response.data.msg;
          this.userSaved = true;
        })
        .catch((response) => {
          this.alert = response && response.data && response.data.error;
        });
    },
    // onConfirm() {
    //   this.sending = true;
    //   this.signupUser({
    //     formData: this.form,
    //     wantsToParticipateInSummarizerProject: true,
    //   }).then((response) => {
    //     this.sending = false;
    //     this.userSaved = true;
    //     this.emailSentMsg = response.data.msg;
    //   });
    // },
    // onCancel() {
    //   this.sending = true;
    //   this.signupUser({ formData: this.form })
    //     .then((response) => {
    //       this.sending = false;
    //       this.userSaved = true;
    //       this.emailSentMsg = response.data.msg;
    //     })
    //     .catch(({ response }) => {
    //       this.sending = false;
    //       this.alert = response && response.data && response.data.error;
    //     });
    // },
    validateSignup() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.signup();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.signup-page {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
  .title {
    text-align: center;
    margin-bottom: 30px;
    img {
      margin-bottom: 16px;
      max-width: 80px;
    }
  }
  .terms-and-condition-class {
    padding: 0 !important;
    margin: 0 !important;
    margin-bottom: 20px !important;
  }
  .login-link {
    margin-top: 20px;
    font-size: 16px;
    text-align: initial !important;
  }
  .actions {
    margin-top: 20px;
    .md-button {
      margin: 0;
    }
  }
  .form {
    margin-bottom: 60px;
  }
  .background {
    background: $color-sea-breeze;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;
  }
  .md-content {
    z-index: 1;
    padding: 40px;
    width: 100%;
    max-width: 400px;
    position: relative;
  }
  .loading-overlay {
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .success-class {
    color: $color-sea-breeze;
  }
  .spinner-dialog-box {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
  .message-container {
    max-width: 700px !important;
    max-height: 250px !important;
  }
  .terms-class {
    align-self: flex-end;
    text-align: left;
    padding-left: 1em;
  }
  .align-start {
    align-self: flex-start;
  }
  .brand-logo {
    width: 70%;
    height: 70%;
    margin: 0 !important;
    padding: 0 !important;
  }
  .title-text {
    align-self: center;
  }
}
</style>
