// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import Router from 'vue-router';
import App from './App';
import store from './store';
import router from './router';
import i18n from './i18n';
import VueMaterial from 'vue-material';
import Notifications from 'vue-notification';
import './assets/scss/vue-material.scss';
import Vuelidate from 'vuelidate';
import Vuex from 'vuex';
import { TokenService } from './services/token.service';
import ApiService from './services/api.service';
import 'vue-material/dist/vue-material.css';
import { parseSeconds } from './utils/time';
import vdrag from 'v-drag/src/index';

function fetchEnv(key) {
  if (key in process.env) {
    return process.env[key];
  }
  throw new Error(`Unknown ENV variable ${key}`);
}

Vue.use(VueMaterial);
Vue.use(Vuelidate);
Vue.use(Vuex);
Vue.use(Notifications);
Vue.use(Router);
Vue.use(vdrag);

Vue.mixin({
  methods: {
    parseSeconds,
  },
});

Vue.config.productionTip = false;

Vue.prototype.$environment = process.env;

ApiService.init(`${fetchEnv('VUE_APP_PROTOCOL')}://${fetchEnv('VUE_APP_HOST')}:${fetchEnv('VUE_APP_PORT')}`, router);

if (TokenService.getAccessToken()) {
  ApiService.setHeader();
  ApiService.mount401interceptor();
}

/* eslint-disable no-new */
new Vue({
  i18n,
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>',
});
