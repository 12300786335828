<template>
  <div class="reset-password-page">
    <md-content class="md-elevation-3">
      <div class="title md-layout">
        <md-avatar class="md-primary md-large">
          <img :src="logo" class="svg-img brand-logo" />
        </md-avatar>
        <div class="md-title md-layout-item title-text">
          {{ $t("buttons.resetPassword") }}
        </div>
      </div>

      <form
        id="reset_password_form"
        novalidate
        class="md-layout"
        @submit.prevent="validatePasswordChange"
      >
        <span class="error-class" v-if="error_message">{{
          error_message
        }}</span>
        <div class="success-class" v-if="success">
          <span>{{ $t("resetPassword.resetSuccess") }}</span>
          <a href="/login"> {{ $t("buttons.loginButton") }}</a>
        </div>

        <md-field :class="getValidationClass('password')" md-has-password>
          <label for="password">{{ $t("fieldName.newPassword") }}</label>
          <md-input
            name="password"
            id="password"
            placeholder="New Password"
            v-model="form.password"
            :disabled="sending"
            type="password"
          />
          <span class="md-error" v-if="!$v.form.password.required">{{
            $t("users.passwordRequired")
          }}</span>
          <span class="md-error" v-else-if="!$v.form.password.minLength">{{
            $t("users.passwordInvalidLength")
          }}</span>
          <span class="md-error" v-else-if="!$v.form.password.containsUppercase">{{
            $t("users.passwordInvalidCase")
          }}</span>
          <span class="md-error" v-else-if="!$v.form.password.containsLowercase">{{
            $t("users.passwordInvalidCase")
          }}</span>
          <span class="md-error" v-else-if="!$v.form.password.containsNumber">{{
            $t("users.passwordInvalidNumber")
          }}</span>
          <span class="md-error" v-else-if="!$v.form.password.containsSpecial">{{
            $t("users.passwordInvalidSpecial")
          }}</span>
        </md-field>
        <md-field
          :class="getValidationClass('confirmPassword')"
          md-has-password
        >
          <label for="confirmPassword">{{
            $t("fieldName.confirmPassword")
          }}</label>
          <md-input
            name="confirmPassword"
            id="confirmPassword"
            placeholder="Confirm Password"
            v-model="form.confirmPassword"
            :disabled="sending"
            type="password"
          />
          <span class="md-error" v-if="!$v.form.confirmPassword.required">{{
            $t("users.passwordRequired")
          }}</span>
          <span
            class="md-error"
            v-else-if="!$v.form.confirmPassword.sameAsPassword"
            >{{ $t("users.passwordSame") }}</span
          >
        </md-field>
        <div
          class="actions md-layout md-alignment-center-right"
          v-if="!success"
        >
          <md-button
            type="submit"
            class="md-raised md-primary"
            for="reset_password_form"
            >{{ $t("buttons.passwordChange") }}</md-button
          >
        </div>
      </form>
    </md-content>
  </div>
</template>

<script>
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
import logo from "../assets/Stenoris_Logo_min.svg";

export default {
  name: "LoginPage",
  data: () => ({
    logo,
    form: {
      password: "",
      confirmPassword: "",
    },
    sending: false,
    error_message: "",
    success: false,
  }),
  computed: {
    ...mapGetters("user", {
      fieldLength: "getFieldLength",
      timeout: "getTimeoutDuration",
    }),
  },
  validations() {
    const containsUppercase = (value) => /[A-Z]/.test(value);
    const containsLowercase = (value) => /[a-z]/.test(value);
    const containsNumber = (value) => /[0-9]/.test(value);
    const containsSpecial = (value) => /[#?!@$%^&*-]/.test(value);
    const data = {
      form: {
        password: {
          required,
          minLength: minLength([this.fieldLength.password]),
          containsUppercase,
          containsLowercase,
          containsNumber,
          containsSpecial
        },
        confirmPassword: {
          required,
          sameAsPassword: sameAs("password"),
        },
      },
    };
    return data;
  },
  watch: {
    error_message() {
      if (this.error_message) {
        setTimeout(() => {
          this.error_message = "";
        }, this.timeout);
      }
    },
  },
  methods: {
    ...mapActions("user", ["resetPassword"]),
    passwordReset() {
      this.sending = true;

      var current = this;

      this.resetPassword({
        // #Security...
        new_password: this.form.password,
        token: this.$route.query.token,
      })
        .then((result) => {
          current.success = true;
          current.sending = false;
        })
        .catch(({ response }) => {
          current.success = false;
          current.sending = false;
          current.error_message =
            response && response.data && response.data.error;
        });
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    validatePasswordChange() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.passwordReset();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.reset-password-page {
  background-color: $color-sea-breeze;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
  .title {
    text-align: center;
    margin-bottom: 30px;
    img {
      margin-bottom: 16px;
      max-width: 80px;
    }
  }
  .signup-link,
  .reset-link {
    margin-top: 20px;
    font-size: 16px;
    text-align: initial !important;
    cursor: pointer;
  }
  .actions {
    margin-top: 0px;
    .md-button {
      margin: 0;
    }
  }
  .form {
    margin-bottom: 60px;
  }
  .md-content {
    z-index: 1;
    padding: 40px;
    width: 100%;
    max-width: 400px;
    position: relative;
  }
  .loading-overlay {
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .brand-logo {
    width: 70%;
    height: 70%;
    margin: 0 !important;
    padding: 0 !important;
  }
  .title-text {
    align-self: center;
  }

  .success-class {
    color: $color-sea-breeze;
    text-align: justify;
  }

  .success-class span {
    margin-right: 5px;
  }
}
</style>
