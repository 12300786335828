import Vue from 'vue';
import ApiService from '../../services/api.service';

const state = {
  publicKey: '',
};

const mutations = {
  setPublicKey(state, publicKey) {
    Vue.set(state, 'publicKey', publicKey);
  },
};

const actions = {
  async fetchPublicKey({ commit }) {
    const response = await ApiService.get('/Payment.fetch_public_key');
    commit('setPublicKey', response.data.public_key);
  },
  chargeAmount(_, charge) {
    return ApiService.post('/Payment.create_payment', charge);
  },
  async fetchPayment() {
    const response = await ApiService.get('/Payment.get_payment_list');
    return response.data.data.payment_list;
  },
  async fetchPaymentForUser(_, userId) {
    const response = await ApiService.get(`/Payment.get_payment_list.for_user?user_id=${userId}`);
    return response.data.data.payment_list;
  },
};

const getters = {
  getPublicKey: (state) => {
    return state.publicKey;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
