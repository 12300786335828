<template>
  <div>
    <md-dialog-content id="payInput">
      <div class="sr-root">
        <div class="sr-main">
          <p>{{ $t('payment.enterCardDetails') }}</p>
          <p>{{ $t('payment.amount') }}: {{ amount }} CHF {{ $t('payment.inclusiveVat') }}</p>
          <form id="payment-form" class="sr-payment-form">
            <div class="sr-combo-inputs-row">
              <div class="sr-input sr-card-element" id="card-element"></div>
            </div>
            <div class="sr-field-error" id="card-errors" role="alert" style="color: crimson;"></div>
            <button id="submit" @click.prevent="createPaymentMethod()" :disabled=btnDisabled>
              <div class="spinner hidden" id="spinner"></div>
              <span id="button-text">{{ $t('payment.btnPayment') }}</span><span id="order-amount"></span>
            </button>
          </form>
          <p class="footnote">{{ $t('payment.processedByStripe') }} - <a href="https://stripe.com"
              target="_blank">stripe.com</a></p>
          <p class="footnote"><md-icon>lock</md-icon> <span>{{ $t('payment.cardIsEncrypted') }}</span></p>
        </div>
      </div>
    </md-dialog-content>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: [
    'amount',
  ],
  data() {
    return {
      btnDisabled: false,
      stripe: '',
      elements: null,
      cardElement: null,
      email: '',
    };
  },
  computed: {
    ...mapGetters('user', {
      user: 'getEditedUserDetails',
    }),
    ...mapGetters('payment', [
      'getPublicKey',
    ]),
  },
  async mounted() {
    await this.fetchPublicKey();
    try {
      this.stripe = Stripe(this.getPublicKey);

      this.elements = this.stripe.elements();
    } catch (IntegrationError) {
      this.$notify({ text: this.$t('payment.stripeKeyIssue') });
    }

    const style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    };

    try {
      this.cardElement = this.elements.create('card', { style: style });
      this.cardElement.mount('#card-element');
    } catch (IntegrationError) {
      this.$notify({ text: this.$t('payment.stripeLoadIssue') });
    }

    this.fetchUserDetails();
  },
  methods: {
    ...mapActions('payment', [
      'fetchPublicKey',
      'chargeAmount',
    ]),
    ...mapActions('user', [
      'fetchUserDetails',
    ]),
    changeLoadingState(isLoading) {
      if (isLoading) {
        document.querySelector('#spinner').classList.remove('hidden');
        document.querySelector('#button-text').classList.add('hidden');
        this.btnDisabled = true;
      } else {
        document.querySelector('#spinner').classList.add('hidden');
        document.querySelector('#button-text').classList.remove('hidden');
        this.btnDisabled = false;
      }
    },
    createPaymentMethod() {
      this.changeLoadingState(true);
      this.stripe.createPaymentMethod({
        type: 'card',
        card: this.cardElement,
        billing_details: {
          // Include any additional collected billing details.
          name: this.user.name,
          email: this.user.email,
        },
      }).then(this.stripePaymentMethodHandler);
    },
    stripePaymentMethodHandler(result) {
      if (result.error) {
        this.showError(result.error.message);
      } else {
        const body = {
          payment_method_id: result.paymentMethod.id,
          amount: this.amount,
          receipt_email: this.user.email,
          customer: this.user.stripe_customer_id,
        };
        this.chargeAmount(body).then(response => {
          this.handleServerResponse(response);
        }).catch(_error => {
          this.changeLoadingState(false);
          this.$notify({ text: this.$t('payment.paymentFailed') });
        });
      }
    },
    showError(errorMsgText) {
      this.changeLoadingState(false);
      var errorMsg = document.querySelector('.sr-field-error');
      errorMsg.textContent = errorMsgText;
      setTimeout(() => { errorMsg.textContent = ''; }, 10000);
    },
    handleServerResponse(response) {
      this.changeLoadingState(false);

      if (response.data.error) {
        this.$notify({ text: response.data.error });
      } else if (response.requires_action) {
        this.stripe.handleCardAction(
          response.payment_intent_client_secret,
        ).then(this.handleStripeJsResult);
      } else {
        this.orderComplete(response);
      }
    },
    handleStripeJsResult(result) {
      if (result.error) {
        this.showError(result.error.message);
      } else {
        const body = {
          payment_intent_id: result.paymentIntent.id,
          timeDifference: this.timeDifference,
        };
        this.chargeAmount(body).then((confirmResult) => {
          return confirmResult();
        }).then(this.handleServerResponse);
      }
    },
    orderComplete(clientSecret) {
      this.changeLoadingState(false);
      this.$emit('pay-done');
    },
  },
};

</script>

<style lang="scss">
@import '../assets/scss/payment-stripe.scss';

#payInput {
  padding-top: 0;

  .footnote {
    color: gray;
    text-align: center;
  }
}
</style>
