<template>
  <div class="load-audio">
    <md-field>
      <label>{{ $t("loadAudio.openFile") }}</label>
      <md-file accept="audio/*,video/*,.wav,.m4a,.mp3,.mp4,.ogg,.avi,.wmv" id="audioFileInput"
        @change="setAudioFile($event)" />
    </md-field>
  </div>
</template>

<script>
import ApiService from "../../services/api.service";
import { mapActions } from "vuex";

export default {
  name: "LoadAudio",
  data() {
    return {
      audioFile: {
        file: null,
        localPath: "",
        isTranscribe: true,
        metadata: {
          contentType: "",
        },
      },
      maxFileSizeInBytes: 1024 * 1024 * 1024 // 1 GiB
    };
  },
  methods: {
    setAudioFile(event) {
      // Abort if file input is cancelled
      if (event.target.files[0] === undefined) {
        return;
      }

      this.audioFile.file = event.target.files[0];

      // Abort if file size is too big
      var tooBig = this.audioFile.file.size > this.maxFileSizeInBytes
      this.$emit("set-audio-too-big", tooBig);
      if (tooBig === true) {
        return;
      }

      this.audioFile.localPath = this.audioFile.file.path;
      this.audioFile.metadata.contentType = event.target.files[0].type;
      this.$emit("set-audio-uploading", true);

      // Create hidden form so that the audio file can be sent to the server
      const formData = new FormData();
      formData.append(
        "audio",
        document.querySelector("#audioFileInput").files[0]
      );
      formData.append("contentType", this.audioFile.metadata.contentType);

      // Post the audio file and the content type to the server
      const reqData = {
        method: "post",
        url: "/Audiofile.create",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };
      ApiService.custom(reqData)
        .then((response) => {
          const audio = response.data.audio;
          const audioMetaData = {
            channelCount: audio.audio_channel_count,
            fileType: audio.file_type,
            sampleRate: audio.sample_rate,
            length: audio.length,
            uploadName: this.audioFile.file.name,
          };
          this.$emit("set-audio-uploading", false);
          this.$emit("set-audio-meta", audioMetaData);
          this.$emit("set-audio-file-id", audio.id);
          this.$emit("on-audio-uploaded", audio.length);
        })
        .catch((error) => this.$notify({ text: error }));
    },
  },
};
</script>
