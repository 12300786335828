<template>
  <Page>
    <md-dialog-confirm
      :md-active.sync="deletionConfirmationActive"
      :md-title="$t('transcripts.deleteTitle')"
      :md-content="$t('transcripts.deleteContent')"
      :md-confirm-text="$t('transcripts.deleteConfirm')"
      :md-cancel-text="$t('transcripts.deleteCancel')"
      @md-confirm="deleteProject()"
    />
    <md-dialog :md-active.sync="importProjectDialogActive">
      <div class="close-button">
        <md-button
          @click="importProjectDialogActive = false"
          class="md-icon-button"
        >
          <md-icon>close</md-icon>
        </md-button>
      </div>
      <md-dialog-content class="no-border">
        <ProjectFromITS v-on:imported="$router.push({ name: 'EditorPage' })" />
      </md-dialog-content>
    </md-dialog>
    <div class="md-layout md-alignment-top-left load-file-section">
      <div class="md-layout-item md-size-70 md-alignment-top-left">
        <div class="md-layout md-alignment-top-left">
          <h1>
            {{ $t("dashboard.myProjects") }}
          </h1>
        </div>
        <div class="md-layout">
          <md-field class="md-layout-item md-size-30 sort-by-field">
            <label for="sort">{{ $t("sort.label") }}</label>
            <md-select
              class="sort-by-select"
              v-model="sortedBy"
              name="sort"
              id="sort"
              placeholder="Sort By"
            >
              <md-option
                v-for="sort in sortedByOptions"
                :key="sort"
                :value="sort"
                >{{ $t(`dashboard.sort.${sort}`) }}</md-option
              >
            </md-select>
          </md-field>
          <NewProjectControls
            @show-new-project-dialog="showNewProjectDialog"
            @show-import-project-dialog="showImportProjectDialog"
            class="md-layout-item"
          />
        </div>
        <!-- slot for new empty project without audio -->
        <div v-if="newProjectDialogActive">
          <ProjectListItemEditable
            :project="emptyTranscript()"
            @transcriptCreated="handleCreateProject()"
            @deleteProject="handleDeleteProject($event)"
          ></ProjectListItemEditable>
        </div>
        <!-- all projects with audio -->
        <div class="project-list">
          <div v-for="transcript in sortedTranscripts" :key="transcript.id">
            <ProjectListItemEditable
              v-if="transcript.status == 'Unprocessed'"
              :project="transcript"
              @transcriptionStarted="handleTranscribeProject()"
              @deleteProject="handleDeleteProject($event)"
            ></ProjectListItemEditable>
            <ProjectListItemFixed
              v-else
              :transcript="transcript"
              @deleteProject="handleDeleteProject($event)"
            ></ProjectListItemFixed>
          </div>
        </div>
      </div>
    </div>
    <!-- welcome screen -->
    <!-- <md-dialog
      class="welcome-screen"
      :md-active.sync="welcomeScreenDialogActive"
      @md-clicked-outside="closeWelcomeScreen"
    >
      <WelcomeScreen
        @close-welcome-screen="closeWelcomeScreen"
        @disable-welcome-screen="disableWelcomeScreen = $event"
      >
      </WelcomeScreen>
    </md-dialog> -->
  </Page>
</template>
<script>
import Page from "../components/Page";
import NewProjectControls from "../components/NewProjectControls";
import ExportMixin from "../components/ExportMixin";
import ProjectFromITS from "../components/ProjectFromITS";
import NameExistsError from "../components/NameExistsError";
import ProjectListItemFixed from "../components/items/ProjectListItemFixed.vue";
import ProjectListItemEditable from "../components/items/ProjectListItemEditable.vue";
import { mapMutations, mapActions, mapGetters } from "vuex";
import WelcomeScreen from "../components/WelcomeScreen";

export default {
  components: {
    WelcomeScreen,
    Page,
    NewProjectControls,
    ProjectFromITS,
    NameExistsError,
    ProjectListItemFixed,
    ProjectListItemEditable,
  },
  mixins: [ExportMixin],
  data() {
    return {
      transcripts: [],
      deletingTranscriptId: null,
      deletionConfirmationActive: false,
      newProjectDialogActive: false,
      importProjectDialogActive: false,
      sortedByOptions: [
        "name",
        "lastUpdatedAsc",
        "lastUpdatedDesc",
        "language",
      ],
      sortedBy: "lastUpdatedDesc",
      welcomeScreenDialogActive: false,
      disableWelcomeScreen: false,
    };
  },
  watch: {},
  created() {
    this.fetchLanguages().catch((error) => {
      this.$notify({ text: error });
    });
  },
  computed: {
    ...mapGetters("user", {
      user: "getEditedUserDetails",
    }),
    sortedTranscripts() {
      return this.transcripts.slice().sort((a, b) => {
        if (this.sortedBy === "name") {
          return a.name.localeCompare(b.name);
        } else if (this.sortedBy === "lastUpdatedAsc") {
          return new Date(a.last_updated) - new Date(b.last_updated);
        } else if (this.sortedBy === "lastUpdatedDesc") {
          return new Date(b.last_updated) - new Date(a.last_updated);
        } else if (this.sortedBy === "language") {
          return a.language.localeCompare(b.language);
        }
        return 0;
      });
    },
  },
  methods: {
    ...mapMutations("user", ["setUserDetailProperty"]),
    ...mapActions("transcript", [
      "fetchTranscripts",
      "fetchInterview",
      "deleteTranscript",
      "fetchLanguages",
    ]),
    ...mapActions("user", ["fetchUserDetails", "updateUserShowScreen"]),
    async loadTranscripts() {
      const loadedTranscripts = await this.fetchTranscripts();
      this.transcripts = loadedTranscripts;
      let comparison;
      if (this.sortedBy === "name" || this.sortedBy === "language") {
        comparison = (a, b) =>
          a[this.sortedBy]
            .toLowerCase()
            .localeCompare(b[this.sortedBy].toLowerCase());
      } else if (this.sortedBy === "lastUpdatedAsc") {
        comparison = (a, b) =>
          new Date(a.last_updated) - new Date(b.last_updated);
      } else if (this.sortedBy === "lastUpdatedDesc") {
        comparison = (a, b) =>
          new Date(b.last_updated) - new Date(a.last_updated);
      } else {
        this.transcripts = loadedTranscripts;
        return;
      }
      this.transcripts = loadedTranscripts.slice().sort(comparison);
    },
    openTranscript(transcriptId) {
      const promise = this.fetchInterview(transcriptId);
      promise
        .then(() => {
          this.$router.push({ name: "EditorPage" });
        })
        .catch((error) => {
          this.$notify({ text: error });
        });
    },
    emptyTranscript() {
      return {
        id: null,
        name: "New Project",
        status: "Unprocessed",
        language: "English",
        fileName: "",
        audioLength: null,
      };
    },
    showNewProjectDialog() {
      this.newProjectDialogActive = true;
    },
    showImportProjectDialog() {
      this.importProjectDialogActive = true;
    },
    handleCreateProject() {
      this.newProjectDialogActive = false;
      this.loadTranscripts();
    },
    handleTranscribeProject() {
      this.loadTranscripts();
    },
    handleDeleteProject(projectId) {
      if (Number.isInteger(projectId)) {
        // delete project with audio
        this.deletingTranscriptId = projectId;
        this.deletionConfirmationActive = true;
      } else {
        // delete project without audio
        this.deletingTranscriptId = null;
        this.deletionConfirmationActive = false;
        this.newProjectDialogActive = false;
        this.loadTranscripts();
      }
    },
    deleteProject() {
      if (Number.isInteger(this.deletingTranscriptId)) {
        const promise = this.deleteTranscript(this.deletingTranscriptId);
        promise.then(() => {
          this.loadTranscripts();
        });
        promise.catch((error) => {
          this.$notify({ text: error });
        });
      }
    },
    disableWelcomeScreenForUser() {
      this.setUserDetailProperty({
        property: "show_welcome_screen",
        value: false,
      });
      this.updateUserShowScreen()
        .then(() => {
          this.fetchUserDetails().then(() => {
            this.welcomeScreenDialogActive = this.user.show_welcome_screen;
          });
        })
        .catch(({ response }) => {
          this.$notify({
            text: response && response.data && response.data.error,
          });
        });
    },
    closeWelcomeScreen() {
      if (this.disableWelcomeScreen) {
        this.disableWelcomeScreenForUser();
      }
      this.welcomeScreenDialogActive = false;
    },
    sortProjects() {
      this.transcripts = this.sortedTranscripts;
    },
  },
  mounted: function () {
    this.fetchUserDetails().then(() => {
      this.welcomeScreenDialogActive = this.user.show_welcome_screen;
    });
    this.loadTranscripts();
  },
  directives: {
    focus: {
      inserted(e) {
        e.focus();
      },
    },
  },
};
</script>

<style scoped>
span {
  margin-bottom: 0.5em;
}
.projects-list-item {
  margin: 1em;
  padding: 1em;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(33, 33, 33, 0.12);
}

.load-file-section {
  margin-top: 2em;
  margin-left: 2em;
  margin-right: 2em;
}

.section-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section-btn button {
  margin-left: 6px !important;
}

.section-btn button:disabled {
  background: rgb(189, 189, 189);
}
.item-actions > span {
  float: right;
}

.md-dialog ::v-deep .md-dialog-actions .md-button {
  overflow: visible !important;
}

.md-field {
  margin-right: 2em;
}

.sort-by-field {
  float: right;
}

.welcome-screen ::v-deep .md-dialog-container {
  width: 700px;
}

.no-border {
  padding: 0;
}

@media (max-width: 876px) {
  .md-layout-item.md-size-70 {
    min-width: 100%;
    max-width: 100%;
    flex: initial;
  }
  .projects-list-item {
    grid-template-columns: 1fr;
    grid-gap: 1em;
  }
}
</style>
