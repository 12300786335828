<template>
  <div>
    <form novalidate class="md-layout md-alignment-top-left" @submit.prevent="">
      <div v-if="isProfileError" class="md-layout-item md-size-100">
        <span class="error-class">{{ alert }}</span>
      </div>
      <div v-if="isProfileSuccess" class="md-layout-item md-size-100">
        <span class="success-class">{{ $t("users.emailSentMsg") }}</span>
      </div>
      <div
        class="left-container md-layout-item md-layout md-gutter md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100"
      >
        <div class="md-layout-item md-layout md-size-100">
          <div class="md-layout-item md-size-30">
            <span>{{ $t("fieldName.name") }}</span>
          </div>
          <div class="md-layout-item md-size-60">
            <div v-if="!isUserNameEdit">
              <span class="value-field">{{ user.name }}</span>
            </div>
            <div v-else>
              <md-field>
                <md-input
                  :value="user.name"
                  @input="updateValidUser('name', $event)"
                  :validator="$v.user.name"
                  :disabled="sending"
                ></md-input>
              </md-field>
            </div>

            <span class="md-error" v-if="!$v.user.name.required">{{
              $t("users.nameRequired")
            }}</span>
            <span class="md-error" v-else-if="!$v.user.name.minLength">{{
              $t("users.nameInvalid")
            }}</span>
          </div>
          <div class="md-layout-item md-size-10">
            <md-button
              class="md-icon-button"
              @click="isUserNameEdit = !isUserNameEdit"
              :disabled="disabledBtn"
            >
              <md-icon>edit</md-icon>
            </md-button>
          </div>
        </div>

        <div class="md-layout-item md-layout md-size-100">
          <div class="md-layout-item md-size-30">
            <span>{{ $t("fieldName.email") }}</span>
          </div>
          <div class="md-layout-item md-size-60">
            <div class="" v-if="!isUserEmailEdit">
              <span>
                <a class="value-field" :href="'mailto:' + user.email">
                  {{ user.email }}
                </a>
              </span>
            </div>
            <div class="" v-else>
              <md-field>
                <md-input
                  autocomplete="email"
                  :value="user.email"
                  @input="updateValidUser('email', $event)"
                  :validator="$v.user.email"
                  :disabled="sending"
                ></md-input>
              </md-field>
            </div>

            <span class="md-error" v-if="!$v.user.email.required">{{
              $t("users.emailRequired")
            }}</span>
            <span class="md-error" v-else-if="!$v.user.email.email">{{
              $t("users.emailInvalid")
            }}</span>
          </div>
          <div class="md-layout-item md-size-10">
            <md-button
              class="md-icon-button"
              @click="isUserEmailEdit = !isUserEmailEdit"
              :disabled="disabledBtn"
            >
              <md-icon>edit</md-icon>
            </md-button>
          </div>
        </div>

        <div class="md-layout-item md-layout md-size-100">
          <div class="md-layout-item md-size-30">
            <span>{{ $t("users.userMinutes") }}</span>
          </div>
          <div class="md-layout-item md-size-60">
            <div>
              <span class="value-field">{{ user.credits }}</span>
            </div>
          </div>
          <div class="md-layout-item md-size-10">
            <div class="md-layout-item md-size-15">
              <md-button
                class="md-raised md-primary"
                @click="requestCharging()"
                >{{ $t("buttons.charge") }}</md-button
              >
            </div>
          </div>
        </div>

        <div class="md-layout-item md-layout md-size-100">
          <div class="md-layout-item md-size-30">
            <span>{{ $t("fieldName.userId") }}</span>
          </div>
          <div class="md-layout-item md-size-60">
            <span class="value-field">{{ userId }}</span>
          </div>
        </div>

        <div class="md-layout-item md-layout md-size-100">
          <div class="md-layout-item md-size-30">
            <span>{{ $t("fieldName.signupDate") }}</span>
          </div>
          <div class="md-layout-item md-size-60">
            <span class="value-field">{{
              new Date(user.created).toLocaleDateString()
            }}</span>
          </div>
        </div>
      </div>
    </form>
    <div class="md-layout md-gutter md-alignment-bottom-left buttons-container">
      <div class="save-button-container md-layout-item md-size-15">
        <md-button
          type="submit"
          class="md-raised md-primary save-button"
          :disabled="sending || disabledBtn"
          @click="validateUserDetails"
          >{{ $t("buttons.saveButton") }}</md-button
        >
      </div>
    </div>
    <div class="del-account-container">
      <span>
        <a href.prevent="" @click="showConfDialog">{{
          $t("buttons.deleteAccount")
        }}</a>
      </span>
    </div>

    <md-dialog class="spinner-dialog-box" :md-active.sync="isEmailConfirm">
      <Spinner></Spinner>
    </md-dialog>

    <md-dialog
      class="del-account-dialogbox"
      :md-active.sync="showDialog"
      :md-fullscreen="false"
    >
      <md-dialog-title>
        <span>{{ $t("deleteConfDialog.warningMsg") }}</span>
      </md-dialog-title>
      <md-dialog-actions>
        <md-button
          class="md-primary close-dialog"
          @click="cancelDeleteAccount"
          >{{ $t("buttons.cancelButton") }}</md-button
        >
        <md-button
          class="md-primary confirm-dialog"
          @click="confirmDeleteAccount"
          >{{ $t("buttons.confirmButton") }}</md-button
        >
      </md-dialog-actions>
    </md-dialog>

    <md-dialog
      class="del-account-dialogbox"
      :md-active.sync="showByeMessage"
      :md-fullscreen="false"
    >
      <md-dialog-title>
        <span>{{ $t("deleteConfDialog.thankYou") }}</span>
      </md-dialog-title>
      <md-dialog-content>
        <span>
          {{ $t("deleteConfDialog.thankYouMessage") }}
        </span>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button
          class="md-primary confirm-dialog"
          @click="completeDeleteAccount()"
          >{{ $t("buttons.okey") }}</md-button
        >
      </md-dialog-actions>
    </md-dialog>

    <md-dialog
      :md-active.sync="this.readyforCharging"
      :md-fullscreen="false"
      :md-click-outside-to-close="true"
    >
      <md-dialog-title>
        <span>{{ $t("transactions.charge") }}</span>
      </md-dialog-title>
      <md-dialog-content>
        <md-field>
          <label>{{ $t("transactions.currency") }} </label>
          <md-input v-model="creditAmount" type="number" min="1"></md-input>
        </md-field>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-raised md-primary" @click="requestPayment()">{{
          $t("buttons.charge")
        }}</md-button>
        <md-button class="md-primary" @click="abortCharging()">Close</md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog
      :md-active.sync="this.readyForPayment"
      :md-close-on-esc="false"
      :md-click-outside-to-close="false"
    >
      <div class="close-button" id="btnClosePaymentCard">
        <md-button @click="makePaymentCard = false" class="md-icon-button">
          <md-icon>close</md-icon>
        </md-button>
      </div>
      <md-dialog-title>Payment</md-dialog-title>
      <PaymentStripe
        @pay-done="paymentDone()"
        :amount="parseFloat(creditAmount)"
      >
      </PaymentStripe>
    </md-dialog>
  </div>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Spinner from "./ProgressSpinner";
import PaymentStripe from "./PaymentStripe";

export default {
  name: "ProfileTab",
  components: {
    Spinner,
    PaymentStripe,
  },
  data: () => {
    return {
      userEmail: "",
      isUserNameEdit: false,
      isUserEmailEdit: false,
      isProfileError: false,
      isProfileSuccess: false,
      isEmailConfirm: false, // spinner flag.
      emailChangeFlag: false,
      sending: false,
      alert: "",
      userSaved: false,
      showDialog: false,
      showByeMessage: false,
      disabledBtn: false,
      makePaymentCard: false,
      chargeCredits: false,
      creditAmount: 1,
    };
  },
  computed: {
    ...mapGetters("user", {
      user: "getEditedUserDetails",
      fieldLength: "getFieldLength",
      timeout: "getTimeoutDuration",
      padValue: "getPadValue",
    }),
    // Check whether email field is changed or not.
    isEmailChange() {
      return this.user.email !== this.userEmail;
    },
    userId() {
      return this.padValue + this.user.id;
    },
    readyForPayment() {
      return this.makePaymentCard;
    },
    readyforCharging() {
      return this.chargeCredits;
    },
  },
  watch: {
    isProfileError() {
      if (this.isProfileError) {
        setTimeout(() => {
          this.isProfileError = false;
        }, this.timeout);
      }
    },
    isProfileSuccess() {
      if (this.isProfileSuccess) {
        setTimeout(() => {
          this.isProfileSuccess = false;
        }, this.timeout);
      }
    },
    // Update user email at loading time.
    user() {
      if (!this.emailChangeFlag) {
        this.userEmail = this.user.email;
        this.emailChangeFlag = true;
      }
    },

    showByeMessage() {
      if (!this.showByeMessage) {
        this.logout();
      } else {
        this.$emit("disable-setting-close");
        this.disabledBtn = true;
      }
    },
  },
  mounted() {
    // fetch data from database.
    this.fetchUserDetails();
  },
  methods: {
    ...mapActions("user", [
      "fetchUserDetails",
      "updateUserDetails",
      "ensureStripeAccountExists",
      "generateEmailConfirmationUrl",
      "deletedUserAccount",
    ]),
    ...mapMutations("user", [
      "setUserDetails",
      "setUserDetailProperty",
      "resetProfileTab",
    ]),
    confirmDeleteAccount() {
      this.deleteAccount();
    },
    cancelDeleteAccount() {
      this.showDialog = false;
    },
    requestCharging() {
      this.chargeCredits = true;
    },
    abortCharging() {
      this.chargeCredits = false;
    },
    async requestPayment() {
      await this.ensureStripeAccountExists();
      this.makePaymentCard = true;
      this.chargeCredits = false;
    },
    deleteAccount() {
      this.deletedUserAccount()
        .then(() => {
          this.showDialog = false;
          this.showByeMessage = true;
        })
        .catch((error) => {
          this.alert = error.data.error;
        });
    },
    logout() {
      this.$store
        .dispatch("JWT/logout")
        .then(() => {
          this.$router.push("/login");
        })
        .catch(() => {});
    },
    completeDeleteAccount() {
      this.showByeMessage = false;
    },

    // Show the confirmation dialog for delete account.
    showConfDialog() {
      this.showDialog = true;
    },

    getValidationClass(fieldName) {
      const field = this.$v.user[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },

    editUserName() {
      this.isUserNameEdit = true;
    },
    editUserEmail() {
      this.isUserEmailEdit = true;
    },
    sendConfirmationEmail() {
      this.generateEmailConfirmationUrl()
        .then(() => {
          this.fetchUserDetails();
          this.isProfileSuccess = true;
          this.isEmailConfirm = false;
        })
        .catch((error) => {
          this.isProfileError = true;
          this.isEmailConfirm = false;
          this.alert = error.data.error;
        })
        .finally(() => {
          this.sending = false;
        });
    },
    saveUserDetails() {
      this.sending = true;
      this.updateUserDetails()
        .then(() => {
          if (this.isEmailChange) {
            this.isEmailConfirm = true;
            this.sendConfirmationEmail();
          }
          this.userSaved = true;
          this.$emit("close-setting-dialog");
        })
        .catch(({ response }) => {
          this.isProfileError = true;
          this.alert = response.data.error;
        })
        .finally(() => {
          this.sending = false;
        });
    },
    // update value in user vuex mutation.
    updateValidUser(property, value) {
      this.setUserDetailProperty({
        property,
        value,
      });
      this.$v.user[property].$touch();
    },
    validateUserDetails() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.saveUserDetails();
      }
    },
  },

  validations() {
    const data = {
      user: {
        name: {
          required,
          minLength: minLength([this.fieldLength.name]),
        },
        email: {
          required,
          email,
        },
      },
    };
    return data;
  },
};
</script>

<style scoped>
.setting_tabs .md-layout-item span {
  font-size: 16px;
  float: left;
  padding-top: 1rem;
}

.setting_tabs .md-layout-item button {
  float: left;
  vertical-align: top;
}
.change-password-btn-container {
  grid-template-columns: 25rem 8rem 25rem;
  grid-gap: 10px;
  align-items: left;
}

.change-password-btn-container button {
  width: 80%;
}

.left-container .md-size-100 {
  padding: 8px !important;
}

.profile-divider {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.37);
  margin-top: 20px !important;
  min-width: 80% !important;
}

.profile-divider.md-layout-item,
.change-password-container.md-layout-item h2,
.password-checkbox-container .password-checkbox {
  margin-left: 1px !important;
}

.save-button-container .save-button {
  margin-left: 0.5rem !important;
}
.del-account-container {
  font-size: 16px;
  align-items: left;
  margin-top: 2rem;
}
.del-account-container a {
  cursor: pointer;
  color: #ffffff !important;
  background-color: #f13e2d !important;
  border-radius: 3%;
  padding: 0.5rem;
  margin-left: 0.5em;
  float: left;
}
.cancel-container .cancel-button {
  margin-left: 16px !important;
}

.password-checkbox.md-checkbox.md-theme-default.md-checked.md-primary
  ::v-deep
  .md-checkbox-container {
  background-color: #54a1a9 !important;
}

.buttons-container {
  min-height: 10vh !important;
  align-items: flex-end;
}

.error-class {
  margin-left: 8px;
}
.success-class {
  margin-left: 8px;
  color: #54a1a9;
}

.md-dialog-actions .md-button {
  overflow: visible !important;
}

.del-account-dialogbox {
  max-width: 500px;
}

.spinner-dialog-box {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.md-layout-item.md-size-60 {
  max-width: 350px;
  min-width: 100px;
}

.md-layout-item.md-size-30 {
  max-width: 250px;
  min-width: 75px;
  text-align: left;
}
</style>
