<template>
  <md-field class="playback-speed">
    <label for="playbackSpeed">{{ $t('editorSettings.playbackSpeed') }}</label>
    <md-select v-model="playbackSpeed" id="playbackSpeed">
      <md-option value="0.5">0.5x</md-option>
      <md-option value="0.75">0.75x</md-option>
      <md-option value="1">1x</md-option>
      <md-option value="1.25">1.25x</md-option>
      <md-option value="1.5">1.5x</md-option>
      <md-option value="1.75">1.75x</md-option>
      <md-option value="2">2x</md-option>
    </md-select>
  </md-field>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { AudioBus } from '../audioBus';

export default {
  name: 'PlaybackSpeed',
  props: [],
  computed: {
    ...mapGetters('transcript', [
      'getPlaybackSpeed',
    ]),
    playbackSpeed: {
      set(speed) {
        const speedF = parseFloat(speed);
        AudioBus.$emit('changePlaybackRate', speedF);
        this.setPlaybackSpeed(parseFloat(speedF));
      },
      get() {
        return this.getPlaybackSpeed;
      },
    },
  },
  methods: {
    ...mapMutations('transcript', [
      'setPlaybackSpeed',
    ]),
  },
};
</script>

<style scoped>
.playback-speed {
  min-width: 110px;
  max-width: 110px;
}
</style>
