<template>
  <div class="md-layout-item">
    <input
      v-if="editName || (!isValidName && isNameChecked) || isCurrentlyValidating"
      class="project-name-input"
      :value="editedTranscriptName"
      @blur="$emit('update'); onProjectEdit($event, transcript);"
      v-on:keyup.enter="$emit('update'); onProjectEdit($event, transcript);"
      v-bind:disabled="isCurrentlyValidating"
      v-focus>
      <h3 v-else class="project-name" @click="editName=true; $emit('update'); setTemporaryName(transcript);">{{transcript.name}}</h3>
    <NameExistsError class="name-exists-error" v-if="!isValidName && isNameChecked"></NameExistsError>
    <audio
      ref="htmlPlayer"
      controls
      controlslist="nodownload"
      class="play"
      @error="audioLoadingError"
      v-on:play="start"
      v-on:pause="stop"
      v-on:timeupdate="syncTranscriptToAudio"
    >
      <source :src="audioSrc" type="audio/wav" />
      <source :src="audioSrc" type="audio/mpeg" />
      <source :src="audioSrc" type="audio/ogg" />
      Your browser does not support the audio element.
    </audio>
    <button class="stepBackwardButton" @click="jumpBack(audioStep)">
        <img class="stepIcon" :src="stepBackwardIcon" />
        <md-tooltip md-direction="top">{{ $t('editor.wordContextMenu.jumpBack') }}</md-tooltip>
    </button>
    <button class="stepForwardButton" @click="jumpForth(audioStep)">
        <img class="stepIcon" :src="stepForwardIcon" />
        <md-tooltip md-direction="top">{{ $t('editor.wordContextMenu.jumpForth') }}</md-tooltip>
    </button>
   <input class="audioStepper" type="number" :min="0" :max="99999" :steps="1" v-model="audioStep" v-on:change="setAudioStep($event.target.value)">
  <div v-if="isInvalidInput" class="invalid-input-message">
     {{ $t('editor.invalidAudioStepperInput') }}
  </div>
  </div>

</template>

<script>
import { AudioBus } from '../audioBus';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import NameExistsError from './NameExistsError';
import stepForwardIcon from '../assets/svgs/solid/step-forward.svg';
import stepBackwardIcon from '../assets/svgs/solid/step-backward.svg';

const setAudioStep = function(val) {
  return {
    get: function() {
      return this.getJumpStep;
    },
    set: function(val) {
      this.setJumpStep(val);
    },
  };
};

export default {
  name: 'AudioPlayer',
  props: ['transcript'],
  components: {
    NameExistsError,
  },
  data: function() {
    return {
      stepForwardIcon,
      stepBackwardIcon,
      playbackRate: 1.0,
      LOOP_PADDING: 1000,
      lastPauseTime: 0,
      currentAudioTime: 0,
      isLooping: null,
      isPlaying: false,
      editName: false,
      isNameChecked: false,
      isValidName: false,
      isCurrentlyValidating: false,
      editedTranscriptName: '',
      isInvalidInput: false,
    };
  },
  computed: {
    ...mapGetters('JWT', ['access_token']),
    ...mapGetters('transcript', [
      'getInterviewFilePath',
      'getJumpStep',
      'getAudioState',
    ]),
    audioSrc() {
      return `${this.getInterviewFilePath}&jwt=${this.access_token}`;
    },
    audioStep: setAudioStep('audioStep'),
    jumpStep: {
      get: function() {
        return this.getJumpStep;
      },
      set: function(val) {
        this.setJumpStep(val);
      },
    },
  },
  methods: {
    ...mapMutations('transcript', [
      'setTranscriptNameInEditor',
      'setJumpStep',
      'setAudioState',
    ]),
    ...mapActions('transcript', [
      'suggestProjectName',
      'updateTranscriptNameInEditor',
      'isValidProjectName',
    ]),
    play(startTime) {
      this.isPlaying = true;
      this.stopLoop();
      this.$refs.htmlPlayer.currentTime = startTime || this.lastPauseTime;
      this.setAudioState(this.isPlaying);
      this.$refs.htmlPlayer.play();
    },
    stop() {
      this.isPlaying = false;
      this.setAudioState(this.isPlaying);
      this.stopLoop();
      this.$refs.htmlPlayer.pause();
      this.lastPauseTime = this.$refs.htmlPlayer.currentTime;
      AudioBus.$emit('setAudioState', this.isPlaying);
    },
    start() {
      this.isPlaying = true;
      this.setAudioState(this.isPlaying);
    },
    resumeAtEditPosition(time) {
      this.$refs.htmlPlayer.currentTime = this.currentAudioTime - time;
      this.$refs.htmlPlayer.play();
    },
    resumeBeforeAfterEditPosition(time) {
      this.$refs.htmlPlayer.currentTime = time;
      this.$refs.htmlPlayer.play();
    },
    keyupHandler(event) {
      if (event.altKey && event.code === 'KeyK') {
        this.jumpForth(this.audioStep);
      }
      if (event.altKey && event.code === 'KeyJ') {
        this.jumpBack(this.audioStep);
      }
    },
    setAudioStep() {
      if (isNaN(this.audioStep) || this.audioStep.trim() === '') {
        this.isInvalidInput = true;
        this.audioStep = '';
      } else {
        let value = parseInt(this.audioStep);
        if (value < 0) {
          this.audioStep = 0;
        } else if (value > 99999) {
          this.audioStep = 99999;
        }
        this.isInvalidInput = false;
        this.setJumpStep(this.audioStep);
      }
    },
   jumpForth(step) {
    step = parseInt(step) || this.getJumpStep;
    if (Number.isInteger(step)) {
      this.$refs.htmlPlayer.currentTime += step;
      this.isInvalidInput = false; // Resetting invalid input flag
    } else {
      this.isInvalidInput = true;
    }
  },

  jumpBack(step) {
    step = parseInt(step) || this.getJumpStep;
    if (Number.isInteger(step)) {
        this.$refs.htmlPlayer.currentTime -= step;
        this.isInvalidInput = false; // Resetting invalid input flag
    } else {
      this.isInvalidInput = true;
    }
  },
    toggle() {
      if (this.getAudioState === true) {
        this.stop();
      } else {
        this.play();
      }
    },
    loopy({ startTime, endTime }) {
      this.stopLoop();
      this.$refs.htmlPlayer.currentTime = startTime;
      // 1000 for ms, add padding 2 avoid machine gun fx
      const loopRange = 1000 * (endTime - startTime) + this.LOOP_PADDING;
      this.isLooping = setInterval(() => {
        this.$refs.htmlPlayer.currentTime = startTime;
        this.isPlaying = true;
        this.setAudioState(this.isPlaying);
        this.$refs.htmlPlayer.play();
      }, loopRange);
    },
    stopLoop() {
      if (this.isLooping) {
        clearInterval(this.isLooping);
      }
    },
    setPlaybackRate(playbackRate) {
      this.$refs.htmlPlayer.playbackRate = parseFloat(playbackRate);
    },
    syncTranscriptToAudio() {
      AudioBus.$emit('updateActivePhrase', this.$refs.htmlPlayer.currentTime);
      this.currentAudioTime = this.$refs.htmlPlayer.currentTime;
    },
    audioLoadingError() {
      console.log('not implemented');
    },
    async onProjectEdit(e, transcript) {
      this.editName = false;
      this.editedTranscriptName = e.target.value;
      const oldTranscriptName = transcript.name;
      const newTranscriptName = this.editedTranscriptName;
      if (oldTranscriptName === newTranscriptName) {
        this.isValidName = true;
        this.isNameChecked = true;
        return;
      }
      this.isNameChecked = false;
      this.isCurrentlyValidating = true;
      this.isValidName = await this.isValidProjectName(newTranscriptName);
      this.isNameChecked = true;
      this.isCurrentlyValidating = false;
      if (this.isValidName) {
        this.setTranscriptNameInEditor(newTranscriptName);
        await this.updateTranscriptNameInEditor(newTranscriptName);
      };
    },
    setTemporaryName(transcript) {
      this.editedTranscriptName = transcript.name;
    },
  },
  created() {
    AudioBus.$on('play', this.play);
    AudioBus.$on('start', this.start);
    AudioBus.$on('jumpBack', this.jumpBack);
    AudioBus.$on('resumeAtEditPosition', this.resumeAtEditPosition);
    AudioBus.$on('resumeBeforeAfterEditPosition', this.resumeBeforeAfterEditPosition);
    AudioBus.$on('stop', this.stop);
    AudioBus.$on('toggle', this.toggle);
    AudioBus.$on('loopy', this.loopy);
    AudioBus.$on('loadAudioFile', this.loadAudioFile);
    AudioBus.$on('changePlaybackRate', this.setPlaybackRate);
  },
  mounted() {
    document.addEventListener('keyup', this.keyupHandler);
  },
  beforeDestroy() {
    AudioBus.$off('play', this.play);
    AudioBus.$off('start', this.start);
    AudioBus.$off('jumpBack', this.jumpBack);
    AudioBus.$off('resumeAtEditPosition', this.resumeAtEditPosition);
    AudioBus.$off('resumeBeforeAfterEditPosition', this.resumeBeforeAfterEditPosition);
    AudioBus.$off('stop', this.stop);
    AudioBus.$off('toggle', this.toggle);
    AudioBus.$off('loopy', this.loopy);
    AudioBus.$off('loadAudioFile', this.loadAudioFile);
    AudioBus.$off('changePlaybackRate', this.setPlaybackRate);
  },
  destroyed() {
    document.removeEventListener('keyup', this.keyupHandler);
  },
  directives: {
    focus: {
      inserted(e) {
        e.focus();
      },
    },
  },
};
</script>

<style scoped>
  audio {
    height: 32px !important;
    width: 100%;
    margin: 15px 0;
  }

  .stepBackwardButton, .stepForwardButton {
    margin: 5px;
    padding: 5px;
  }
  .stepIcon {
    width: 14px;
    height: 14px;
  }
  .audioStepper {
    width: 50px;
    height: 29px;
  }

  .project-name {
    margin-bottom: 0;
    color: grey;
    font-size: 14px;
    cursor: pointer;
  }

  .project-name-input {
    margin-top: 14px;
    width: 50%;
  }

  .name-exists-error {
    width: 50%;
    text-align: left;
    margin: auto;
  }

  .invalid-input-message {
    color: red;
  }

  @media (max-width: 600px) {
    audio {
      margin: 5px 0 0 0;
    }
  }

</style>
