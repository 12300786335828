<template>
  <div class="projects-list-item">
    <div class="project-base-info">
      <div class="item-actions">
        <span>
          <md-button
            :md-ripple="false"
            class="md-icon-button md-accent"
            @click="deleteProject()"
          >
            <md-icon>delete</md-icon>
          </md-button>
        </span>
      </div>

      <!-- title -->
      <div class="title-grid">
        <div>
          <md-field class="field-project-name">
            <label>{{ $t("loadAudio.projectNameLabel") }}</label>
            <md-input
              v-model="transcriptName"
              @change="setTranscriptName($event.target.value)"
            ></md-input>
          </md-field>
        </div>
      </div>

      <!-- audio file upload -->
      <div v-if="!Number.isInteger(this.transcript.id)">
        <LoadAudio
          @set-audio-uploading="setAudioUploading($event)"
          @set-audio-meta="setAudioMeta($event)"
          @set-audio-file-id="setAudioFileId($event)"
          @set-audio-too-big="setAudioTooBig($event)"
          @on-audio-uploaded="onAudioUploaded($event)"
        ></LoadAudio>
        <span class="md-error" v-if="isAudioTooBig"
          >{{ $t("loadAudio.sizeTooBig") }} (> 1 GB)</span
        >
        <div v-if="isUploadingAudio">
          <md-progress-spinner
            :md-diameter="30"
            :md-stroke="3"
            md-mode="indeterminate"
          ></md-progress-spinner>
          <span class="spinner-text">{{ $t("loadAudio.spinnerText") }}</span>
        </div>
      </div>

      <!-- rest of transcript attributes -->
      <div v-else>
        <div class="projects-list-grid">
          <div class="project-meta">
            <div>
              <span>
                <md-icon>insert_drive_file</md-icon>
                {{ this.transcript.file_name }}
                <md-tooltip md-direction="right">{{
                  $t("audioStatistics.fileName")
                }}</md-tooltip>
              </span>

              <div class="project-meta">
                <div>
                  <span>
                    <md-icon>timer</md-icon>
                    {{ formatAudioLength(this.transcript.audio_length) }}
                    <md-tooltip md-direction="right">{{
                      $t("audioStatistics.fileLength")
                    }}</md-tooltip>
                  </span>
                </div>
              </div>
              <div class="project-meta">
                <div>
                  <span>
                    <div>
                      {{ $t("audioStatistics.status") }}:
                      {{ this.transcript.status }}
                    </div>
                  </span>
                </div>
              </div>
            </div>

            <div>
              <md-field>
                <label for="dropLanguage">{{
                  $t("audioSettings.selectLanguageLabel")
                }}</label>
                <md-select
                  v-model="selectedLanguage"
                  id="dropLanguage"
                  md-dense
                >
                  <md-option
                    v-for="language in getAvailableLanguages"
                    :key="language.toUpperCase()"
                    :value="language.toUpperCase()"
                    >{{ cleanLanguage(language) }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <div>
              <md-switch
                class="md-primary"
                v-model="confidential"
                :disabled="isCalculatingCredits"
              >
                {{
                  confidential
                    ? $t("loadAudio.confidential")
                    : $t("loadAudio.non-confidential")
                }}
              </md-switch>
            </div>
          </div>
        </div>

        <!-- payment info -->
        <div class="section-actions">
          <!-- <div v-if="isTranscribing">
              <ProgressBar
                v-bind:max-duration="maxDuration"
                ref="progressbar"
              ></ProgressBar>
            </div> -->
          <div>
            <p>
              {{ $t("loadAudio.transcribeDuration") }}:
              <strong>~ {{ transcribeDuration }}</strong> min
            </p>
            <div v-if="paymentData.price_in_chf > 0">
              <table>
                <tr>
                  <th>{{ $t("payment.fileLength") }}</th>
                  <td>{{ formattedAudioLength }} min</td>
                </tr>
                <tr>
                  <th>{{ $t("payment.userCredits") }}</th>
                  <td>{{ paymentData.user_credits }}</td>
                </tr>
                <tr>
                  <th>{{ $t("payment.creditsPerMinute") }}</th>
                  <td>{{ paymentData.credits_per_minute }}</td>
                </tr>
                <tr>
                  <th>{{ $t("payment.creditsNeeded") }}</th>
                  <td>{{ paymentData.credits_needed }}</td>
                </tr>
                <tr>
                  <th>{{ $t("payment.creditsMissing") }}</th>
                  <td>{{ paymentData.credits_missing }}</td>
                </tr>
                <tr>
                  <th>{{ $t("payment.total") }}</th>
                  <td>{{ paymentData.price_in_chf }} CHF</td>
                </tr>
              </table>
              <p>
                *
                {{
                  $t("payment.footnote", {
                    minimum_price: paymentData.minimum_price_in_chf,
                  })
                }}
              </p>
              <!-- notify on done checkbox -->
              <md-checkbox class="md-primary" v-model="notifyOnDone">{{
                $t("loadAudio.notify")
              }}</md-checkbox>
              <div class="transcribe-btns">
                <!-- payment button -->
                <md-button
                  class="md-primary md-raised btnPayment"
                  @click="requestPayment()"
                  >{{ $t("payment.btnToPayment") }}</md-button
                >
              </div>
            </div>
            <div v-else>
              <p>
                {{
                  $t("loadAudio.finish.coveredByCredits", {
                    amount: remainingCredits,
                  })
                }}
              </p>

              <!-- notify on done checkbox -->
              <md-checkbox class="md-primary" v-model="notifyOnDone">{{
                $t("loadAudio.notify")
              }}</md-checkbox>
              <div class="transcribe-btns">
                <!-- transcribe button -->
                <md-button
                  v-bind:disabled="!Number.isInteger(this.transcript.id)"
                  class="md-raised md-primary"
                  v-on:click="transcribeAudioFile()"
                  >{{ $t("loadAudio.start") }}</md-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- TODO move to dashboard? -->
    <md-dialog
      :md-active.sync="this.readyForPayment"
      :md-close-on-esc="false"
      :md-click-outside-to-close="false"
    >
      <div class="close-button" id="btnClosePaymentCard">
        <md-button @click="makePaymentCard = false" class="md-icon-button">
          <md-icon>close</md-icon>
        </md-button>
      </div>
      <md-dialog-title>Payment</md-dialog-title>
      <PaymentStripe
        @pay-done="paymentDone()"
        :amount="parseFloat(paymentData.price_in_chf)"
      >
      </PaymentStripe>
    </md-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LoadAudio from "./LoadAudio";
import ProgressBar from "../ProgressBar";
import ApiService from "../../services/api.service";
import PaymentStripe from "../PaymentStripe";
import i18n from "../../i18n";
import { formatDurationInMinutes } from "../../utils/time";
export default {
  name: "ProjectListItemEditable",
  components: {
    LoadAudio,
    ProgressBar,
    PaymentStripe,
  },
  props: {
    project: Object,
  },
  data: () => ({
    transcript: {},
    paymentData: {},

    audio_file_id: null,

    isUploadingAudio: false,
    isAudioTooBig: false,
    makePaymentCard: false,
    isCalculatingCredits: false,
  }),
  computed: {
    ...mapGetters("transcript", ["getAvailableLanguages"]),
    formattedAudioLength() {
      return formatDurationInMinutes(this.transcript.audio_length);
    },
    maxDuration() {
      return 8 * 60 + this.transcript.audio_length / 10;
    },
    transcribeDuration() {
      return formatDurationInMinutes(this.maxDuration);
    },
    remainingCredits() {
      return this.paymentData.user_credits - this.paymentData.credits_needed;
    },
    readyForPayment() {
      return this.paymentData.credits_needed > 0 && this.makePaymentCard;
    },
    selectedLanguage: {
      set(lang) {
        this.setTranscriptLanguage(lang);
      },
      get() {
        return this.transcript.language;
      },
    },
    transcriptName: {
      set(name) {},
      get() {
        return this.transcript.name;
      },
    },
    notifyOnDone: {
      set(value) {
        this.setTranscriptNotifyOnDone(value);
      },
      get() {
        return this.transcript.notify_on_done;
      },
    },
    confidential: {
      set(value) {
        this.setTranscriptConfidential(value);
      },
      get() {
        return this.transcript.confidential;
      },
    },
  },
  mounted() {
    this.updateTranscript(this.project);
  },
  watch: {
    project(val) {
      this.updateTranscript(val);
    },
  },
  methods: {
    ...mapActions("transcript", ["fetchTranscripts", "updateTranscriptName"]),
    ...mapActions("user", ["ensureStripeAccountExists"]),
    async requestPayment() {
      await this.ensureStripeAccountExists();
      this.makePaymentCard = true;
    },
    updatePaymentData() {
      this.isCalculatingCredits = true;
      const transcriptionData = {
        transcript_id: this.transcript.id,
      };
      ApiService.post("/Transcript.calculate_credits", transcriptionData)
        .then((response) => {
          this.paymentData = response.data.data;
        })
        .catch((error) => this.$notify({ text: error }))
        .finally(() => {
          this.isCalculatingCredits = false;
        });
    },
    updateTranscript(newTranscript) {
      this.copyTranscript(newTranscript);
      if (Number.isInteger(this.transcript.id)) {
        this.updatePaymentData();
      }
    },
    copyTranscript(t) {
      this.transcript = { ...t };
    },
    setAudioUploading(bool) {
      this.isUploadingAudio = bool;
    },
    setAudioMeta(meta) {
      this.transcript.file_name = meta.uploadName;
      this.transcript.audio_length = meta.length;
      this.notifyOnDone = meta.length >= 15 * 60;
    },
    formatAudioLength(length) {
      return `${formatDurationInMinutes(length)} min`;
    },
    setAudioTooBig(value) {
      // console.log("isAudioTooBig: " + value)
      this.isAudioTooBig = value;
    },
    paymentDone() {
      this.makePaymentCard = false;
      this.transcribeAudioFile();
    },
    deleteProject() {
      // console.log("deleting: " + this.transcript.id);
      this.$emit("deleteProject", this.transcript.id);
    },
    async setAudioFileId(audioFileId) {
      this.audio_file_id = audioFileId;
    },
    setTranscriptName(name) {
      this.transcript.name = name;
      // console.log("NAME CHANGE: " + this.transcript.name);
      if (Number.isInteger(this.transcript.id)) {
        ApiService.post("/Transcript.update_name", {
          transcript_id: this.transcript.id,
          name: name,
        });
      }
    },
    setTranscriptLanguage(language) {
      this.transcript.language = language;
      // console.log("LANGUAGE CHANGE: " + this.transcript.language);
      if (Number.isInteger(this.transcript.id)) {
        ApiService.post("/Transcript.update_language", {
          transcript_id: this.transcript.id,
          language: language.toUpperCase(),
        });
      }
    },
    setTranscriptNotifyOnDone(value) {
      this.transcript.notify_on_done = value;
      // console.log("NOTIFYONDONE CHANGE: " + this.transcript.notify_on_done);
      if (Number.isInteger(this.transcript.id)) {
        ApiService.post("/Transcript.update_notify", {
          transcript_id: this.transcript.id,
          notify_on_done: value,
        });
      }
    },
    setTranscriptConfidential(value) {
      this.transcript.confidential = value;
      if (Number.isInteger(this.transcript.id)) {
        ApiService.post("/Transcript.update_confidential", {
          transcript_id: this.transcript.id,
          confidential: value,
        }).then((response) => {
          this.updatePaymentData();
        });
      }
    },
    cleanLanguage(language) {
      const languageSplit = language.split("_");
      const capitalized = languageSplit.map(
        (split) => split.charAt(0).toUpperCase() + split.substring(1)
      );
      return capitalized.join(" ");
    },
    async onAudioUploaded() {
      const transcriptData = {
        audio_file_id: this.audio_file_id,
        name: this.transcript.name,
        language: this.transcript.language,
        nb_speakers: 2, // set to 2 to perform speaker diarization in backend
        notify_on_done: this.transcript.notify_on_done,
        confidential: true, // default true when creating a new transcript
      };
      ApiService.post("/Transcript.create", transcriptData).then(
        async (response) => {
          // this.transcriptionId = response.data.transcript_id;
          this.transcript.id = response.data.transcript_id;
          console.log("transcript id: ", this.transcript.id);

          this.$emit("transcriptCreated");
        }
      );
    },
    async transcribeAudioFile() {
      // const currentComponent = this;
      const transcriptionData = {
        transcript_id: this.transcript.id,
      };
      ApiService.post("/Transcript.transcribe", transcriptionData)
        .then(async (response) => {
          this.$emit("transcriptionStarted");
        })
        .catch((error) => this.$notify({ text: error }));
    },
  },
};
</script>

<style scoped>
.transcribe-btns {
  display: grid;
}

span {
  margin-bottom: 0.5em;
}

.projects-list-item {
  margin: 1em;
  padding: 1em;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(33, 33, 33, 0.12);
}

.projects-list-grid {
  margin-top: 0.5em;
}

.title-grid {
  display: grid;
  grid-template-columns: 4fr 1fr;
}

.project-base-info {
  text-align: left;
  margin-bottom: 1em;
}

.project-title-input {
  line-height: 1;
  font-size: 2em;
  padding-bottom: 0px;
  width: 90%;
}

.project-title-edit {
  line-height: 1;
  font-size: 2em;
  word-break: break-word;
  cursor: pointer;
}

.last-edited {
  text-align: left;
  padding-bottom: 1em;
}

.load-file-section {
  margin-top: 2em;
  margin-left: 2em;
  margin-right: 2em;
}

.section-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section-btn button {
  margin-left: 6px !important;
}

.section-btn button:disabled {
  background: rgb(189, 189, 189);
}

.section-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1em;
}

.project-meta > div {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  word-break: break-word;
  text-align: left;
  align-items: flex-start;
}

.item-actions > span {
  float: right;
}

.section-progress {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  word-break: break-word;
  text-align: left;
  align-items: flex-start;
}

.project-meta > div .audio-length {
  margin-left: 36px;
}

.md-dialog ::v-deep .md-dialog-actions .md-button {
  overflow: visible !important;
}

.md-field {
  margin-right: 2em;
}

.sort-by-field {
  float: right;
}

.welcome-screen ::v-deep .md-dialog-container {
  width: 700px;
}

.no-border {
  padding: 0;
}

@media (max-width: 876px) {
  .btn-container {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  .md-layout-item.md-size-70 {
    min-width: 100%;
    max-width: 100%;
    flex: initial;
  }

  .projects-list-item {
    grid-template-columns: 1fr;
    grid-gap: 1em;
  }

  .project-meta > div {
    align-items: left;
  }
}
</style>
