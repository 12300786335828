<template>
  <div class="projects-list-item">
    <div class="project-base-info">
      <div class="item-actions">
        <span>
          <md-button
            :md-ripple="false"
            class="md-icon-button md-accent"
            @click="deleteProject()"
          >
            <md-icon>delete</md-icon>
          </md-button>
        </span>
      </div>

      <!-- title -->
      <div class="title-grid">
        <label class="project-title-input">{{ transcript.name }}</label>
      </div>
      <!-- rest of transcript attributes -->
      <div>
        <div class="projects-list-grid">
          <div class="project-meta">
            <div>
              <span>
                <md-icon>insert_drive_file</md-icon>
                {{ this.transcript.file_name }}
                <md-tooltip md-direction="right">{{
                  $t("audioStatistics.fileName")
                }}</md-tooltip>
              </span>
              <span>
                <md-icon>language</md-icon>
                {{ this.cleanLanguage(this.transcript.language) }}
                <md-tooltip md-direction="right">{{
                  $t("audioStatistics.fileName")
                }}</md-tooltip>
              </span>
              <span>
                <md-icon>timer</md-icon>
                {{ formatAudioLength(this.transcript.audio_length) }}
                <md-tooltip md-direction="right">{{
                  $t("audioStatistics.fileLength")
                }}</md-tooltip>
              </span>
              <div>
                <div>
                  {{ $t("audioStatistics.status") }}:
                  {{ this.transcript.status }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- open button -->
      <div class="section-actions">
        <button
          class="primary-large-btn"
          @click="this.openTranscript"
          :disabled="transcript.status != 'Success'"
        >
          {{ $t("dashboard.open") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { formatDurationInMinutes } from "../../utils/time";
export default {
  name: "ProjectListItemFixed",
  components: {},
  props: {
    transcript: Object, // Assuming 'transcript' is an object
  },
  data: () => ({}),
  computed: {},
  mounted() {},
  watch: {},
  methods: {
    ...mapActions("transcript", ["fetchInterview"]),
    formatAudioLength(length) {
      return `${formatDurationInMinutes(length)} min`;
    },
    deleteProject() {
      this.$emit("deleteProject", this.transcript.id);
    },
    cleanLanguage(language) {
      const languageSplit = language.toLowerCase().split("_");
      const capitalized = languageSplit.map(
        (split) => split.charAt(0).toUpperCase() + split.substring(1)
      );
      return capitalized.join(" ");
    },
    openTranscript() {
      const promise = this.fetchInterview(this.transcript.id);
      promise
        .then(() => {
          this.$router.push({ name: "EditorPage" });
        })
        .catch((error) => {
          this.$notify({ text: error });
        });
    },
  },
};
</script>

<style scoped>
span {
  margin-bottom: 0.5em;
}
.projects-list-item {
  margin: 1em;
  padding: 1em;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(33, 33, 33, 0.12);
}

.project-base-info {
  text-align: left;
  margin-bottom: 1em;
}

.projects-list-grid {
  margin-top: 0.5em;
}

.title-grid {
  display: grid;
  grid-template-columns: 4fr 1fr;
}

.project-title-input {
  line-height: 1;
  font-size: 2em;
  padding-bottom: 0px;
  width: 90%;
}

.section-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1em;
}

.project-meta > div {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  word-break: break-word;
  text-align: left;
  align-items: flex-start;
}
.project-meta > div .audio-length {
  margin-left: 36px;
}
.item-actions > span {
  float: right;
}

@media (max-width: 876px) {
  .btn-container {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
  .md-layout-item.md-size-70 {
    min-width: 100%;
    max-width: 100%;
    flex: initial;
  }
  .projects-list-item {
    grid-template-columns: 1fr;
    grid-gap: 1em;
  }

  .project-meta > div {
    align-items: left;
  }
}
</style>
